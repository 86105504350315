body {
  @media screen and (max-width: $break-small) {
    font-size: var(--wp--preset--font-size--small) !important;
  }
}

h2 {
  @media screen and (max-width: $break-small) {
    font-size: var(--wp--preset--font-size--large) !important;
  }
}

:where(.wp-block-columns.has-background) {
  // Matches paragraph block padding.
  padding: var(--wp--preset--spacing--30);

  @include break-small() {
    padding: $block-bg-padding--v $block-bg-padding--h;
  }
}

figure {
  margin: 0;
}
.wp-block-columns {
  margin-bottom: 0;
}

.aligncenter {
  display: block;
  margin: 0 auto;
  img {
    display: block;
    margin: 0 auto;
  }
}

.alignright {
  float: right;
  margin: 15px 0 15px 15px;
  @media screen and (max-width: 960px) {
    float: none;
    margin: 15px auto;
  }
}

.alignleft {
  float: left;
  margin: 15px 15px 15px 0;
  @media screen and (max-width: 960px) {
    float: none;
    margin: 15px auto;
  }
}

p {
  margin-top: var(--wp--preset--spacing--60);
  margin-bottom: var(--wp--preset--spacing--60);
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
